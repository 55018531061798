/**
 * Reusable helper functions for WP API
 */
import acfContactDetails from "~/data/acf-contact_details.json";
import acfSocialMedia from "~/data/acf-social_media.json";
import acfClientReviews from "~/data/acf-client_reviews.json";
import acfClientReviewLinks from "~/data/acf-client_review_links.json";

const acfOptionCache: any = {
    social_media: acfSocialMedia,
    contact_details: acfContactDetails,
    client_reviews: acfClientReviews,
    client_review_links: acfClientReviewLinks,
};

export const generateAPIUrl = (baseUrl: string, params: any = {}, endpoint: string = "wp/v2/") => {
    const config = useRuntimeConfig();
    const query = new URLSearchParams(params);

    return config.public.wp_api_url + endpoint + baseUrl + '?' + query.toString();
};

export const useWpData = async (path: string, fields: any = {}, endpoint: string = "wp/v2/") => {
    const config = useRuntimeConfig();
    const url = generateAPIUrl(path, fields, endpoint);
    const basicAuth = btoa(`${config.wpUsername}:${config.wpPassword}`);

    return useFetch(url, {
        headers: {'Authorization': `Basic ${basicAuth}`},
        server: true,
    });
}

export const useAcfOption = async (field_name: string) => {
    if (field_name in acfOptionCache) {
        return acfOptionCache[field_name];
    }

    const {data} = await useWpData('acf-options', {field_name});
    acfOptionCache[field_name] = data.value;

    return acfOptionCache[field_name];
}
